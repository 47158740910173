import ApiService from '@/core/services/api.service';

// action types
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_STATISTIAL = 'GET_STATISTIAL';
export const GET_OPTIONS = 'GET_OPTIONS';

// mutation types
export const GET_OPTIONS_COMPLETED = 'GET_OPTIONS_COMPLETED';
export const SET_MODAL = 'SET_MODAL';
export const SET_STATISTICAL = 'SET_STATISTICAL';
export const RESET_STATE = 'RESET_STATE';

// API
const $api = new ApiService();

const state = {
  customers: [],
  statistical: [],
  coaches: [],
  groups: [],
};

const getters = {};

const actions = {
  [GET_CUSTOMERS]: async ({ commit }, params) => {
    try {
      commit('context/setLoading', true, { root: true });
      const { meta, data, error } = await $api.get('/GroupDashboard', {
        params,
      });
      if (!meta.success) return { error };

      const payload = {
        total: data.items?.total || 0,
        items: data.items?.items || [],
        statistical: data.statistics,
      };
      return payload;
    } catch (error) {
      return { error };
    } finally {
      commit('context/setLoading', false, { root: true });
    }
  },

  [GET_STATISTIAL]: async ({ commit }, params) => {
    try {
      const { meta, data, error } = await $api.get(
        '/GroupDashboard/GetStatistic',
        {
          params,
        },
      );

      if (!meta.success) return { error };

      commit(SET_STATISTICAL, data);
    } catch (error) {
      return { error };
    }
  },

  [GET_OPTIONS]: async ({ commit }, params) => {
    try {
      const { meta, data, error } = await $api.get(
        '/GroupDashboard/GetExtend',
        {
          params,
        },
      );
      if (!meta.success) return { error };

      commit(GET_OPTIONS_COMPLETED, data);
    } catch (error) {
      commit(GET_OPTIONS_COMPLETED, {
        coaches: [],
        trainingGroups: [],
      });
      return { error };
    }
  },
};

const mutations = {
  [SET_MODAL]: (state, [variable, value]) => {
    state[variable] = value;
  },

  [RESET_STATE]: (state) => {
    state.calenders = [];
    state.modalCalender = false;
  },

  [SET_STATISTICAL]: (state, payload) => {
    state.statistical = payload || [];
  },

  [GET_OPTIONS_COMPLETED]: (state, payload) => {
    state.coaches = payload.coaches.map((el) => ({ id: el.id, name: el.name }));
    state.groups = payload.trainingGroups;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
