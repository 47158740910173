export default {
    namespaced: true,
    state: {
        course: {},
        coachId: null,
        coachName: '',
    },
    actions: {
        setCourse({ commit }, course) {
            commit('SET_COURSE', course);
        },
        setCoachId({ commit }, coachId) {
            commit('SET_COACHID', coachId);
        },
        setCoachName({ commit }, coachName) {
            commit('SET_COACHNAME', coachName);
        },
    },
    mutations: {
        SET_COURSE(state, course) {
            state.course = course;
        },
        SET_COACHID(state, coachId) {
            state.coachId = coachId;
        },
        SET_COACHNAME(state, coachName) {
            state.coachName = coachName;
        },
    },
}
