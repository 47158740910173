import ApiService from '@/core/services/api.service';

// action types
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTION = 'GET_QUESTION';
export const CREATE_ANSWER = 'CREATE_ANSWER';
export const DELETE_ANSWER = 'DELETE_ANSWER';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const CLOSE_QUESTION = 'CLOSE_QUESTION';

// mutation types
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_ERROR = 'GET_QUESTION_ERROR';
export const RESET_STATE = 'RESET_STATE';

const $api = new ApiService();

const state = {
  questions: [],
};
const getters = {};

const actions = {
  [GET_QUESTIONS]: async ({ commit }, params = {}) => {
    try {
      commit('context/setLoading', true, { root: true });
      const { meta, data, error } = await $api.get('/Question', { params });
      if (!meta.success) {
        commit(GET_QUESTIONS_ERROR);
        return { error: error.message };
      }
      commit(GET_QUESTIONS_SUCCESS, data);
      return {
        error: null,
        data,
        paging: {
          page: meta.page,
          size: meta.size,
          total: meta.total,
        },
      };
    } catch (error) {
      commit(GET_QUESTIONS_ERROR);
      return { error: error.message };
    } finally {
      commit('context/setLoading', false, { root: true });
    }
  },
  [DELETE_ANSWER]: async (context, id) => {
    try {
      const { meta, error } = await $api.delete(`/Question/DeleteAnswer/${id}`);
      if (!meta.success) {
        return { error: error.message };
      }
      return { error: null };
    } catch (error) {
      return { error: error.message };
    }
  },
  [DELETE_QUESTION]: async ({ commit }, id) => {
    try {
      commit('context/setLoading', true, { root: true });
      const { meta, error } = await $api.delete(`/Question/Input/${id}`);
      if (!meta.success) {
        return { error: error.message };
      }
      return { error: null };
    } catch (error) {
      return { error: error.message };
    } finally {
      commit('context/setLoading', false, { root: true });
    }
  },
  [CLOSE_QUESTION]: async ({ commit }, id) => {
    try {
      commit('context/setLoading', true, { root: true });
      const { meta, error } = await $api.put(`/Question/CloseQuestion/${id}`);
      if (!meta.success) {
        return { error: error.message };
      }
      return { error: null };
    } catch (error) {
      return { error: error.message };
    } finally {
      commit('context/setLoading', false, { root: true });
    }
  },
  [GET_QUESTION]: async (context, id) => {
    try {
      const { meta, data, error } = await $api.get(`/Question/${id}`);
      if (!meta.success) {
        return { error: error.message };
      }
      return {
        error: null,
        data,
      };
    } catch (error) {
      return { error: error.message };
    }
  },
  [CREATE_ANSWER]: async (context, params) => {
    try {
      const { meta, data, error } = await $api.post(
        '/Question/CreateAnswer',
        params,
      );
      if (!meta.success) {
        return { error: error.message };
      }
      return {
        error: null,
        data,
      };
    } catch (error) {
      return { error: error.message };
    }
  },
};

const mutations = {
  [RESET_STATE]: (state) => {
    state.questions = [];
  },
  [GET_QUESTIONS_SUCCESS]: (state, payload) => {
    state.questions = payload;
  },
  [GET_QUESTIONS_ERROR]: (state) => {
    state.questions = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
