import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';

import context from './context.module';
import setting from './setting.module';
import menu from './menu.module';
import coach from './coach.module';
import calender from './calender.module';
import groupDashboard from './groupDashboard.module';
import quize from './quize.module';
import customer from './customer.module';
import survey from './survey.module';
import movementRoute from './movementRoute.module';
import userDashboard from './userDashboard.module';
import question from './question.module';
import customerReceives from './customerReceives.module';
import calendarCoach from './calendarCoach.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    context,
    setting,
    menu,
    coach,
    calender,
    groupDashboard,
    quize,
    customer,
    survey,
    movementRoute,
    userDashboard,
    question,
    customerReceives,
    calendarCoach
  },
});
