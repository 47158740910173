import ApiService from '@/core/services/api.service';

// action types
export const GET_FOOD_TEMPLATES = 'GET_FOOD_TEMPLATES';
export const GET_MENU_DETAIL = 'GET_MENU_DETAIL';
export const CHANGE_STATUS = 'CHANGE_STATUS';

// mutation types
export const GET_FOOD_TEMPLATES_SUCCESS = 'GET_FOOD_TEMPLATES_SUCCESS';
export const GET_FOOD_TEMPLATES_FAILED = 'GET_FOOD_TEMPLATES_FAILED';
export const GET_MENU_DETAIL_SUCCESS = 'GET_MENU_DETAIL_SUCCESS';
export const GET_MENU_DETAIL_FAILED = 'GET_MENU_DETAIL_FAILED';
export const SET_MODAL = 'SET_MODAL';
export const SET_BODY_FOOD_TEMPLATE = 'SET_BODY_FOOD_TEMPLATE';
export const SET_DELETE_FOOD = 'SET_DELETE_FOOD';
export const SET_SUBMIT_FOOD = 'SET_SUBMIT_FOOD';

const $api = new ApiService();

const state = {
  totalKcal: 0,
  listdayfood: [],
  showModalFood: false,
  bodyCreateFoodTemplate: null,
  menuDetail: null,
  deleteFoodSuccess: false,
  submitFoodSuccess: false,
};
const getters = {};

const actions = {
  [GET_FOOD_TEMPLATES]: async ({ commit }, params = {}) => {
    try {
      const res = await $api.get('/FoodTemplate', { params });
      commit(GET_FOOD_TEMPLATES_SUCCESS, res);
      return { error: null };
    } catch (error) {
      commit(GET_FOOD_TEMPLATES_FAILED);
      return { error: error };
    }
  },
  [GET_MENU_DETAIL]: async ({ commit }, params) => {
    try {
      const { data } = await $api.get(`/FoodMenu/${params.id}`);
      commit(GET_MENU_DETAIL_SUCCESS, data);
      return { data, error: null };
    } catch (error) {
      commit(GET_MENU_DETAIL_FAILED);
      return { error: error, data: null };
    }
  },
  [CHANGE_STATUS]: async (context, params) => {
    try {
      const { meta, data } = await $api.put(
        `/FoodMenu/active/${params.id}`,
        params,
      );
      if (meta.success) {
        return { data, error: null };
      }
      return { error: true, data: null };
    } catch (error) {
      return { error: error, data: null };
    }
  },
};

const mutations = {
  [GET_FOOD_TEMPLATES_SUCCESS]: (state, payload) => {
    state.totalKcal = payload.totalKcal;
    state.listdayfood = payload.listdayfood;
  },
  [GET_FOOD_TEMPLATES_FAILED]: (state) => {
    state.totalKcal = 0;
    state.listdayfood = [];
  },
  [SET_MODAL]: (state, payload) => {
    state.showModalFood = payload;
  },
  [SET_DELETE_FOOD]: (state, payload) => {
    state.deleteFoodSuccess = payload;
  },
  [SET_BODY_FOOD_TEMPLATE]: (state, payload) => {
    state.bodyCreateFoodTemplate = payload;
  },
  [SET_SUBMIT_FOOD]: (state, payload) => {
    state.submitFoodSuccess = payload;
  },
  [GET_MENU_DETAIL_SUCCESS]: (state, payload) => {
    state.menuDetail = payload;
  },
  [GET_MENU_DETAIL_FAILED]: (state) => {
    state.menuDetail = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
